import Prismic from 'prismic-javascript';
import HeroCarousel from 'containers/Slices/HeroCarousel';
import TwoImages from 'containers/Slices/TwoImages';
import CategoryGrid from 'containers/Slices/CategoryGrid';
import ImageOnly from 'containers/Slices/ImageOnly';
import FreeText from 'containers/Slices/FreeText';
import RangeGrid from 'containers/Slices/RangeGrid';
import TwoColumns from 'containers/Slices/TwoColumns';
import ThreeColumns from 'containers/Slices/ThreeColumns';
import RangesColumns from 'containers/Slices/RangesColumns';
import ColumnsCarousel from 'containers/Slices/ColumnsCarousel';
import RangesCarousel from 'containers/Slices/RangesCarousel';
import TextsOnly from 'containers/Slices/TextsOnly';
import LogosBanner from 'containers/Slices/LogosBanner';
import YoutubeVideoWithoutPlay from 'containers/Slices/YoutubeVideoWithoutPlay';
import InstagramBlock from 'containers/Slices/InstagramBlock';
import TestimonialSlides from 'containers/Slices/TestimonialSlides';
import ValuePropBlock from 'containers/Slices/ValuePropBlock';
import HTMLTextsOnly from 'containers/Slices/HTMLTextsOnly';
import TwoColumnsVideoWithoutPlay from 'containers/Slices/TwoColumnsVideoWithoutPlay';
import HolidayOffers from 'containers/Slices/HolidayOffers';
import TrustersList from 'containers/Slices/ImprovementSlices/TrustersList';
import AwardsList from 'containers/Slices/ImprovementSlices/AwardsList';
import LargeReviewsCarousel from 'containers/Slices/ImprovementSlices/LargeReviewsCarousel';
import SmallReviewsCarousel from 'containers/Slices/ImprovementSlices/SmallReviewsCarousel';
import ProductsByCategoryCarousel from 'containers/Slices/ImprovementSlices/ProductsByCategoryCarousel';
import ProductsCarousel from 'containers/Slices/ImprovementSlices/ProductsCarousel';
import VideoEmdedCode from 'containers/Slices/VideoEmdedCode';
import ThreeCategorySlice from 'containers/Slices/ThreeCategorySlice';
import CtaSlice from 'containers/Slices/CtaSlice';
import LocationBanner from 'containers/Slices/LocationSlice';
import UltracoreCarousel from 'containers/Slices/ImprovementSlices/UltracoreCarousel';
import TestimonialTitleLogos from 'containers/Slices/ImprovementSlices/TestimonialTitleLogos';
import TestimonialCarousel from 'containers/Slices/ImprovementSlices/TestimonialCarousel';
import DesktopsCarousell from 'containers/Slices/DesktopsCarousell';
import Category from 'containers/Slices/ImprovementSlices/Category';
import Awards from 'containers/Slices/ImprovementSlices/Awards';
import EmbedVideo from 'containers/Slices/ImprovementSlices/EmbedVideo';

const prismicApiEndpoint = process.env.NEXT_PUBLIC_PRISMIC_URL;
const prismicAccessToken = process.env.NEXT_PUBLIC_PRISMIC_TOKEN;

export default Prismic.client(prismicApiEndpoint, {
  accessToken: prismicAccessToken,
});

export const getSimpleClient = (ref = null) =>
  Prismic.getApi(prismicApiEndpoint, {
    accessToken: prismicAccessToken,
  });

export async function getDocumentByUid(type, uid, ref) {
  const documents = await getSimpleClient(ref)
    .then((api) =>
      api.query(
        Prismic.Predicates.at(`my.${type}.uid`, uid),
        ref
          ? {
              lang: '*',
              ref,
            }
          : { lang: '*' },
      ),
    )
    .then((response) => response.results)
    .catch((error) => {
      console.log(error);
      console.log('Please check line 40 in file helpers/Prismic.js');
    });

  if (!documents?.length) return null;

  return documents[0];
}
export async function getDocumentByHandle(type, uid, ref) {
  const documents = await getSimpleClient()
    .then((api) =>
      api.query(
        Prismic.Predicates.at(`my.${type}.handle`, uid),
        ref
          ? {
              lang: '*',
              ref,
            }
          : { lang: '*' },
      ),
    )
    .then((response) => response.results)
    .catch((error) => {
      console.log(error);
      console.log('Please check line 63 in file helpers/Prismic.js');
    });

  if (!documents?.length) return null;

  return documents[0];
}

export async function getDocumentsByTag(tags) {
  const documents = await getSimpleClient()
    .then((api) => api.query(Prismic.Predicates.at('document.tags', tags)))
    .then((response) => response.results)
    .catch((error) => {
      console.log(error);
      console.log('Please check line 87 in file helpers/Prismic.js');
    });

  return documents;
}

export async function getDocumentsByType(type, options = {}) {
  const documents = await getSimpleClient()
    .then((api) =>
      api.query(Prismic.Predicates.at('document.type', type), options),
    )
    .then((response) => response.results)
    .catch((error) => {
      console.log(error);
      console.log('Please check line 99 in file helpers/Prismic.js');
    });

  return documents;
}

export async function getStaticPathsByType(type, paramName, replace) {
  const documents = await getSimpleClient()
    .then((api) => api.query(Prismic.Predicates.at('document.type', type)))
    .then((response) => response.results)
    .catch((error) => {
      console.log(error);
      console.log('Please check line 113 in file helpers/Prismic.js');
    });

  return documents.map((doc) => ({
    params: { [paramName]: doc.uid.replace(replace, '') },
  }));
}

export async function getStaticPathsByTypeMultiple(
  type,
  paramName,
  replace,
  splitBy,
) {
  const documents = await getSimpleClient()
    .then((api) => api.query(Prismic.Predicates.at('document.type', type)))
    .then((response) => response.results)
    .catch((error) => {
      console.log(error);
      console.log('Please check line 127 in file helpers/Prismic.js');
    });

  return documents.map((doc) => ({
    params: { [paramName]: doc.uid.replace(replace, '').split(splitBy) },
  }));
}

export async function getDocumentsTotalSize(type) {
  const totalSize = await getSimpleClient()
    .then((api) => api.query(Prismic.Predicates.at('document.type', type)))
    .then((response) => response.total_results_size)
    .catch((error) => {
      console.log(error);
      console.log('Please check line 146 in file helpers/Prismic.js');
    });

  return totalSize;
}

export const getAllDocumentsByType = async (type) => {
  const queryPcModels = (page = 1) => {
    const pcModelDocType = Prismic.Predicates.at('document.type', type);

    return getSimpleClient()
      .then((api) => api.query(pcModelDocType, { page, pageSize: 5 }))
      .catch((error) => {
        console.log(error);
        console.log('Please check line 158 in file helpers/Prismic.js');
      });
  };

  const firstResponse = await queryPcModels();

  const promises = [];

  for (let page = 2; page <= firstResponse.total_pages; page += 1) {
    promises.push(queryPcModels(page));
  }

  const responses = await Promise.all(promises);

  const docs = [
    ...firstResponse.results,
    ...responses.flatMap((response) => response.results),
  ];

  return docs;
};

export const renderSlices = (block, type, index) => {
  switch (type) {
    case 'hero_banner':
      return <HeroCarousel block={block} key={`${type}-${index}`} />;
    case 'logos_banner':
      return <LogosBanner block={block} key={`${type}-${index}`} />;
    case 'two_columns':
      return <TwoColumns block={block} key={`${type}-${index}`} />;
    case 'cta_slice':
      return <CtaSlice block={block} key={`${type}-${index}`} />;
    case 'youtube_embed_code':
      return <VideoEmdedCode block={block} key={`${type}-${index}`} />;
    // case 'three_columns':
    //   return <ThreeColumns block={block} key={`${type}-${index}`} />;
    // case "ranges_columns":
    //   return <RangesColumns block={block} key={`${type}-${index}`} />;
    case 'three_category_slice':
      return <ThreeCategorySlice block={block} key={`${type}-${index}`} />;
    case 'desktops_carousell':
      return <DesktopsCarousell block={block} key={`${type}-${index}`} />;
    case 'location_banner':
      return <LocationBanner block={block} key={`${type}-${index}`} />;
    case 'columns_carousel':
      return <ColumnsCarousel block={block} key={`${type}-${index}`} />;
    case 'range_carousel1':
      return <RangesCarousel block={block} key={`${type}-${index}`} />;
    case 'texts_only':
      return <TextsOnly block={block} key={`${type}-${index}`} />;
    case 'two_images':
      return <TwoImages block={block} key={`${type}-${index}`} />;
    case 'category_grid':
      return <CategoryGrid block={block} key={`${type}-${index}`} />;
    case 'image_only':
      return <ImageOnly block={block} key={`${type}-${index}`} />;
    case 'free_text':
      return <FreeText block={block} key={`${type}-${index}`} />;
    case 'range_grid':
      return <RangeGrid block={block} key={`${type}-${index}`} />;
    case 'youtube_video':
      return <YoutubeVideoWithoutPlay block={block} key={`${type}-${index}`} />;
    case 'instagram_block':
      return <InstagramBlock block={block} key={`${type}-${index}`} />;
    case 'testimonials_carousel':
      return <TestimonialSlides block={block} key={`${type}-${index}`} />;
    case 'value_prop_block':
      return <ValuePropBlock block={block} key={`${type}-${index}`} />;
    case 'html_text_only':
      return <HTMLTextsOnly block={block} key={`${type}-${index}`} />;
    case 'two_columns_video':
      return (
        <TwoColumnsVideoWithoutPlay block={block} key={`${type}-${index}`} />
      );
    case 'holiday_offers':
      return <HolidayOffers block={block} key={`${type}-${index}`} />;
    case 'truster_list':
      return <TrustersList block={block} key={`${type}-${index}`} />;
    case 'awards_list':
      return <AwardsList block={block} key={`${type}-${index}`} />;
    case 'reviews_carousel':
      if (block?.primary.carousel_item_size === 'Large') {
        return <LargeReviewsCarousel block={block} key={`${type}-${index}`} />;
      } else {
        return <SmallReviewsCarousel block={block} key={`${type}-${index}`} />;
      }
    case 'carousel_by_category':
      return (
        <ProductsByCategoryCarousel block={block} key={`${type}-${index}`} />
      );
    case 'carousel_by_product':
      return <ProductsCarousel block={block} key={`${type}-${index}`} />;
    case 'ultracore_carousel':
      return <UltracoreCarousel block={block} key={`${type}-${index}`} />;
    case 'embed_video':
      return <EmbedVideo  block={block} key={`${type}-${index}`} />;
    case 'testimonial_title___logo':
      return <TestimonialTitleLogos block={block} key={`${type}-${index}`} />;
    case 'testimonial_carousel':
      return <TestimonialCarousel block={block} key={`${type}-${index}`} />;
    case 'category':
      return <Category block={block} key={`${type}-${index}`} />;
    case 'awards':
      return <Awards block={block} key={`${type}-${index}`} />;
    default:
      return '';
  }
};
