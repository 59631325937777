import ReactPlayer from 'react-player';
import Skeleton from 'react-loading-skeleton';
import { useState } from 'react';

const EmbedVideo = ({ block }) => {
  const title = block?.primary?.title1;
  const video_link = block?.primary?.video_link;

  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <div className='embed-video-wrapper'>
        <div className='embed-video'>
          {isLoading && (
            <Skeleton
              height='100%'
              width='100%'
              style={{ aspectRatio: '16/9' }}
              baseColor='#111111'
              highlightColor='#950810'
              duration={1.5}
            />
          )}
          {ReactPlayer && (
            <ReactPlayer
              url={video_link}
              playing
              loop
              lazy='true'
              muted
              controls={false}
              width='100%'
              height='100%'
              onReady={() => setIsLoading(false)}
              config={{
                youtube: {
                  playerVars: {
                    autoplay: 1,
                    mute: 1,
                    loop: 1,
                    controls: 0,
                    modestbranding: 1,
                    rel: 0,
                    cc_load_policy: 0,
                    disablekb: 1,
                    fs: 0,
                    iv_load_policy: 3,
                    hl: 'en',
                    cc_lang_pref: '',
                    cc: 0,
                  },
                },
              }}
              style={{ aspectRatio: '16/9' }}
            />
          )}
        </div>
      </div>

      <style jsx>{`
        .embed-video-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .embed-video {
          position: relative;
          width: 100%;
          height: auto;
          max-width: 1440px;
          padding-bottom: 21px;
          pointer-events: none;
        }
      `}</style>
    </>
  );
};

export default EmbedVideo;
